import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from '../Components/SliderAwards';
import Rankings from '../Images/888.jpg';
import one from '../Images/1.png';
import two from '../Images/2.png';
import three from '../Images/3.jpg';
import five from '../Images/104.png';

const Awards = () => {
  const [awardsSections, setAwardsSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [awardsImages, setAwardsImages] = useState([]);

  useEffect(() => {
    const fetchAwardsSections = async () => {
      try {
        const response = await axios.get('https://back.zubilaw.com/awardstitle/list');
        if (response.data.success) {
          const sections = response.data.data.map(section => ({
            title: section.title,
            imageUrl: section.imageUrl,
            paragraph: section.paragraph || ""
          }));
          setAwardsSections(sections);
        } else {
          console.error('Unexpected response structure:', response.data);
        }
      } catch (error) {
        console.error('Error fetching awards sections:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAwardsSections();
  }, []);

  useEffect(() => {
    const fetchAwardsImages = async () => {
      try {
        const response = await axios.get('https://back.zubilaw.com/awardsdescription/list');
        if (response.data.success) {
          setAwardsImages(response.data.data);
        } else {
          console.error('Unexpected response structure:', response.data);
        }
      } catch (error) {
        console.error('Error fetching awards images:', error);
      }
    };

    fetchAwardsImages();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const enhanceHtml = (html) => {
    return html
      .replace(/<h1>/g, '<h1 class="text-4xl font-bold">')
      .replace(/<li>/g, '<li class="list-disc list-inside">');
  };

  return (
    <>
      <div className="container bg-white items-center mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0 pb-10">
        <Slider Awards="Awards and Rankings" />
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            <div className="flex flex-col space-y-14 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 xl:px-0 pt-20 mb-0">
              {awardsSections.map((section, index) => (
                <React.Fragment key={index}>
                  <div className="flex justify-between items-center mb-0">
                    <div className="w-[50%]">
                      <h2 className="text-4xl font-semibold mb-2 text-[#972a36]">{section.title}</h2>
                      <div className="bg-[#777777] h-0.5 w-16  mt-2 mb-6" ></div>
                      <div dangerouslySetInnerHTML={{ __html: enhanceHtml(section.paragraph) }}></div>
                    </div>
                    {section.imageUrl && (
                      <img src={`https://back.zubilaw.com/${section.imageUrl}`} alt={`Awards ${index}`} className="w-[24%] sm:h-80 lg:h-64 object-contain mx-auto mt-10" />
                    )}
                  </div>
                  <div className="w-full border-t border-gray-300 mb-0 mx-auto" style={{ maxWidth: '50%' }}></div>
                </React.Fragment>
              ))}
            </div>

            <div className="flex justify-center mt-10 mb-20 px-20">
  <div className="flex flex-wrap justify-center gap-7 mt-10 max-w-4xl">
    {awardsImages.map((image, index) => (
      <div key={index} className="flex justify-center w-1/6">
        <img 
          src={`https://back.zubilaw.com/${image.imageUrl}`} 
          alt={`Awards ${index}`} 
          className="w-40 sm:h-40 lg:h-40 object-contain" 
        />
      </div>
    ))}
  </div>
</div>








            <div className="w-full border-t border-gray-300 mb-20 mx-auto" style={{ maxWidth: '50%' }}></div>
          </>
        )}
      </div>
    </>
  );
};

export default Awards;
